var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('labels.customer_name'),"label-for":"customer_name"}},[_c('b-form-input',{attrs:{"id":"customer_name","value":_vm.searchFields.customer_name},on:{"input":function($event){return _vm.inputChange($event, 'customer_name')}}})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('labels.contact_number'),"label-for":"contact_number"}},[_c('b-form-input',{attrs:{"id":"contact_number","value":_vm.searchFields.contact_number},on:{"input":function($event){return _vm.inputChange($event, 'contact_number')}}})],1)],1)],1)],1)],1),_c('TableIndex',{attrs:{"resource-name":_vm.resourceName,"singular-name":_vm.singularName,"filter-fields":_vm.searchFields,"have-dropdown-actions":true,"custom-update-object":_vm.custom_update_object},scopedSlots:_vm._u([{key:"custom_actions",fn:function(ref){
var item = ref.item;
return [((item.actions && item.actions.can_view))?_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"ml-1",attrs:{"title":_vm.$t('details'),"to":{ name: 'sale-requests-details', params: { resourceId: item.id } }}},[_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"EyeIcon","size":"16"}})],1):_vm._e()]}},{key:"dropdown_actions",fn:function(ref){
var item = ref.item;
var generalActions = ref.generalActions;
return [(item.actions && item.actions.can_upload_field_evaluation_report)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.showModal(item)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Upload field evaluation')))])],1):_vm._e(),(item.actions && item.actions.can_generate_financial_offer)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.generateFinancialOffer(item)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Upload financial offer')))])],1):_vm._e(),(item.actions && item.actions.can_generate_sale_contract_file)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.generateSaleContract(item)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('generate-sale-contract')))])],1):_vm._e()]}}])}),_c('b-modal',{ref:"modal-upload-file",attrs:{"id":"modal-upload-file","cancel-variant":"outline-secondary","ok-title":_vm.$t('yes'),"cancel-title":_vm.$t('no'),"centered":"","title":_vm.$t('Upload field evaluation'),"hide-footer":"","cancel-disabled":false}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.uploadFile.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"vid":"file_name","name":_vm.$t('field evaluation'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"custom-uploader-input",attrs:{"for":"file-input"},on:{"drop":function($event){return _vm.handleDrop($event)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.file_name ? _vm.file_name : _vm.$t('Choose a file or drop it here...'))+" ")]),_c('b-form-file',{ref:"file-input",staticClass:"d-none",attrs:{"id":"file-input","placeholder":_vm.$t('Choose a file or drop it here...'),"drop-placeholder":_vm.$t('Drop file here...'),"no-drop":"","accept":".pdf"},on:{"change":function($event){return _vm.uploader($event)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex"},[_c('LoadingButton'),(_vm.file_name)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-danger"},on:{"click":_vm.clearFiles}},[_vm._v(" "+_vm._s(_vm.$t('delete file'))+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.hideModal}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }